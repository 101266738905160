var herojs = (function($){
  var _global = {
    wp: 0,
    rothei: parseInt( $('.rotator__container').css('height') , 10),
    navhei: parseInt( $('nav.topheader').css('height') , 10 ),
  };

 /**
  * initializes Sliders
  */
  var slider = function($target, time, varvertical, vardots, forcenofade, arrowsipnut){
    if(forcenofade){
      fadevar = forcenofade;
    }else{
      fadevar = !varvertical;
    }
    if(arrowsipnut === undefined){
      arrowsipnut = false;
    }
    $target.slick({
      dots: vardots,
      arrows: arrowsipnut,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: time,
      speed: time/3,
      draggable: true,
      swipe: true,
      fade: !varvertical,
      vertical: varvertical,
      pauseOnHover: false,
      pauseOnFocus: false,
      rows: 0,
    });

    var $elements = $target.find('img');
    for(i=0; i<$elements.length;i++){
      var $cur = $($elements[i]);
      $cur.attr('src', $cur.data('manuallazy'));
    }

    $target.find('figcaption').attr('style', 'transition: transform '+time/3+'ms;');
    $target.find('.rotfig__capinner').attr('style', 'transition: transform '+time/2+'ms, opacity '+time/3+'ms; transition-delay: '+time/6+'ms;');
  };

  var controlslider = function($target, time, varvertical, vardots, forcenofade, arrowsipnut, controlslider){
    if(forcenofade){
      fadevar = forcenofade;
    }else{
      fadevar = !varvertical;
    }
    if(arrowsipnut === undefined){
      arrowsipnut = false;
    }
    $target.slick({
      dots: vardots,
      arrows: arrowsipnut,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: time,
      speed: time/3,
      draggable: true,
      swipe: true,
      fade: !varvertical,
      vertical: varvertical,
      pauseOnHover: false,
      pauseOnFocus: false,
      asNavFor: controlslider,
      rows: 0,
    });

    var $elements = $target.find('img');
    for(i=0; i<$elements.length;i++){
      var $cur = $($elements[i]);
      $cur.attr('src', $cur.data('manuallazy'));
    }

    $target.find('figcaption').attr('style', 'transition: transform '+time/3+'ms;');
    $target.find('.rotfig__capinner').attr('style', 'transition: transform '+time/2+'ms, opacity '+time/3+'ms; transition-delay: '+time/6+'ms;');
  };


  /* Play/Pause Hero Video */
  var _videoPauseHandler = function(){
    var herovideo = document.getElementById("herovideo");

    $('#vidpaubu').on('click', function(){
      if( $(this).hasClass('this--paused') ){
        herovideo.play();
      }else{
        herovideo.pause();
      }
      $(this).toggleClass('this--paused');
    });
  };

  var _videoHandler = function(){
    // do nothiiiiing
    // scrollCheckPoint = $(window).width()*0.33;
    // if(scrollCheckPoint >= wp){
    //   _domEl.$fpsect.addClass('this--blurry');
    // }else{
    //   _domEl.$fpsect.removeClass('this--blurry');
    // }

  };


  /**
   * Performas Calculations related to positioning of home hero section
   */
  var _performCalc = function(input){
    _global.wp = $(window).scrollTop();
    _global.rothei = parseInt( $('.rotator__container').css('height') , 10);

    $('.rotator__wrapper').css('height', _global.rothei*1+'px');


    if(input === 'amenities'){
      $('.text-rotator').css('margin-top', '-'+_global.rothei*0.7+'px');
      $('.rotator__wrapper').css('height', _global.rothei*1.2+'px');

      var capinnerwidth = 0;
      if ($(window).width() > 649){
        capinnerwidth = $('.rotfig__capinner').outerWidth(true);
      }else{
        capinnerwidth = 0;
      }
      $('.this--amenities.img-rotator .slick-prev').css('left', capinnerwidth + 'px');
      $('.this--amenities.img-rotator .slick-next').css('left', (capinnerwidth+51) + 'px');

    }else if(input === 'residents'){
      $('.text-rotator').css('margin-top', '-'+_global.rothei*0.5+'px');
      $('.rotator__wrapper').css('height', _global.rothei*1.15+'px');
    }else if(input === 'home2'){

      // a bit of a hack … basically when this border property is present, the aspect ratio of the image is
      // different and we need to perfom a slightly different calculation.
      if( $('.rotfig__imgwrapper').css('borderLeft') === '1px solid rgb(255, 255, 255)'){
        $('.text-rotator').css('margin-top', '-'+_global.rothei*0.9+'px');
        $('.rotator__wrapper').css('height', _global.rothei*1.75+'px');
      }else{
        $('.text-rotator').css('margin-top', '-'+_global.rothei*0.75+'px');
        $('.rotator__wrapper').css('height', _global.rothei*1.75+'px');
      }
      if ( ( $('.rotfig__imgwrapper').css('borderLeft') !== '1px solid rgb(255, 255, 255)') && $(window).width() > 650) {
        $('.text-rotator').css('margin-top', '-'+_global.rothei*0.95+'px');
      }


    }else{
      $('.text-rotator').css('margin-top', '-'+_global.rothei*1+'px');
      $('.rotator__wrapper').css('height', _global.rothei*1.75+'px');
    }
  };

  /**
   * Performs calculations related to navbar
   */
  var _navbarCalc = function(){
    _global.navhei = parseInt( $('nav.topheader').css('height') , 10 );

    if( _global.wp < _global.rothei ){
      $('.topheader').addClass('this--isontop');
    }else{
      $('.topheader').removeClass('this--isontop');
    }

    if( _global.wp > _global.rothei/1.25 ){
      $('nav.topheader').addClass('this--scrolled');
    }else{
      $('nav.topheader').addClass('this--scrolled');
    }
  };

  var _menu = function(){
    $endOfLogo = $('.navlogo__upper').offset().left + $('.navlogo__upper').width() + 30;
    $('.navmenu').css('width', $endOfLogo+'px');
    $('.navmenu').css('left', '-'+$endOfLogo+'px');
  };

  var pausebutton = function(){
    $('.mmcheropausebutton').on('click', function() {
      var $pausetarget = $('.' + $(this).data('pausebuttonfor') );

      if( !$pausetarget.hasClass('this--sliderpaused') ){
        $(this).addClass('this--paused');
        $pausetarget.slick('slickPause').addClass('this--sliderpaused');
      }else{
        $(this).removeClass('this--paused');
        $pausetarget.slick('slickPlay').removeClass('this--sliderpaused');
      }

    });
  };


  var pageheader = function(){
    // _menu();

    $(window).on('resize', function(){
      // _menu();
    });

    $(window).scroll( function(){
      _navbarCalc();
    }); // end window scroll
  };


  /**
   * Basic Hamburger functionality
   *
   * Tab index changes are controlled by a _tabindexcalc
   * function in tylbfs.js.
   */
  var hamburger = function(){
    $('#primary-nav-toggle').on('click', function(){
      $('.navmenu').toggleClass('this--geöffnet');
      $('.topheader').toggleClass('this--geöffnet');
    });
  };


  /**
   * function both visually opens the specials bar
   * and keeps track of the tab index of the controlling elements
   * @param  {string} input optional – can be 'open' or 'close',
   *                                   with generic fallback
   */
  var _specialstoggle = function( input ){
    if( input === 'open' ){
      $('#tylbspecialsbarslider').addClass('this--specialopen');
      $('.newhero').addClass('this--specialopen');
      $('.tylbspecialsbar-trigger--open').attr('tabindex', '-1');
      $('.tylbspecialsbar-trigger--close').attr('tabindex', '0');
      $('.tylbspecialsbar__wrapper a').attr('tabindex', '0');
      $('.mmcheropausebutton--home').attr('tabindex', '-1');
      $(".slick-dots li button[tabindex='0']").attr("tabindex","-1");
    }else if( input === 'close' ){
      $('#tylbspecialsbarslider').removeClass('this--specialopen');
      $('.newhero').removeClass('this--specialopen');
      $('.tylbspecialsbar-trigger--open').attr('tabindex', '0');
      $('.tylbspecialsbar-trigger--close').attr('tabindex', '-1');
      $('.tylbspecialsbar__wrapper a').attr('tabindex', '-1');
      $('.mmcheropausebutton--home').attr('tabindex', '0');
      $(".slick-dots li button[aria-selected='true']").attr("tabindex","0");
    }else{
      $('#tylbspecialsbarslider').toggleClass('this--specialopen');
      $('.newhero').toggleClass('this--specialopen');
      if($('.tylbspecialsbar-trigger--open').attr('tabindex') === '-1' ){
        $('.tylbspecialsbar-trigger--open').attr('tabindex', '0');
        $('.tylbspecialsbar-trigger--close').attr('tabindex', '-1');
        $('.tylbspecialsbar__wrapper a').attr('tabindex', '-1');
        $('.mmcheropausebutton--home').attr('tabindex', '0');
        $(".slick-dots li button[aria-selected='true']").attr("tabindex","0");
      }else{
        $('.tylbspecialsbar-trigger--open').attr('tabindex', '-1');
        $('.tylbspecialsbar-trigger--close').attr('tabindex', '0');
        $('.tylbspecialsbar__wrapper a').attr('tabindex', '0');
        $('.mmcheropausebutton--home').attr('tabindex', '-1');
        $(".slick-dots li button[tabindex='0']").attr("tabindex","-1");
      }
    }
  };



  var _specialsWatch = function(){
    if( _global.wp > 1 ){
      _specialstoggle('close');
    }
  };



  var homeheader = function(input){
    _performCalc(input);
    // _menu();

    $(window).on('resize', function(){
      _performCalc(input);
      // _menu();
    });

    $(window).scroll( function(){
      _performCalc(input);
      _navbarCalc();
      _specialsWatch();
    }); // end window scroll

  };


  /**
   * Public Method that controls click and focus events
   * on the special triggers.
   */
  var specials = function(){
    $('.tylbspecialsbar-trigger--open').on('click', function(e){
      e.stopPropagation();
      _specialstoggle('open');
    });
    $('.tylbspecialsbar-trigger--close').on('click', function(){
      _specialstoggle('close');
    });

  };

  var init = function(){
    // on load if specials is open then make slick dots non tabable
    if($('#tylbspecialsbarslider').hasClass('this--specialopen')){
      $(".slick-dots li button[tabindex='0']").attr("tabindex","-1");
    }

    _videoHandler();
    _videoPauseHandler();
  };

  return{
    controlslider: controlslider,
    hamburger: hamburger,
    homeheader: homeheader,
    pageheader: pageheader,
    pausebutton: pausebutton,
    slider: slider,
    specials: specials,
    init: init,
  };


})(jQuery);


