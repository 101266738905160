var headerjs = (function($){

  var has_hamburger_been_opened = false;

  var _scrollCheck = function(){
    // if scrolled enough, make visible and vise-versa
    if( window.scrollY >= 1 ){
      $(".tpaccheader").addClass("this--scrolled");
      $(".tpacchambuger").addClass("this--scrolled");
    }else{
      if( !has_hamburger_been_opened ){
        $(".tpaccheader").removeClass("this--scrolled");
        $(".tpacchambuger").removeClass("this--scrolled");
      }
    }
  };


  var init = function(){

    _scrollCheck();

    window.addEventListener('scroll', _scrollCheck);

    $(".tpacchambuger").on("click", function(e){
      e.stopPropagation();
      $(this).toggleClass("this--open");
      $(".tpaccnav").toggleClass("this--open");
      $(".mmcmain").toggleClass("this--blurmaster");
      $(".tpaccfooter").toggleClass("this--blurmaster");

      if( $(window).width() < 650 ){
        $(".tpaccheader").addClass("this--scrolled");
        $(".tpacchambuger").addClass("this--scrolled");
        has_hamburger_been_opened = true;
      }

    });

    $("body").on("click", function(){
      console.log('clicked');
      if( $(".tpacchambuger").hasClass("this--open") ){
        $(".tpacchambuger").removeClass("this--open");
        $(".tpaccnav").removeClass("this--open");
        $(".mmcmain").removeClass("this--blurmaster");
        $(".tpaccfooter").removeClass("this--blurmaster");
      }
    });

  };



   /**
  * Public Methods
  */
  return{
    init: init,
  };

})(jQuery);