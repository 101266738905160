/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        var $hamburger = $(".hamburger");

        //slide out menu
/*         var slideout = new Slideout({
          'panel': document.getElementById('sitecontent'),
          'menu': document.getElementById('navigation'),
          'padding': 256,
          'tolerance': 70,
          'side': 'right',
          'touch': false,
        }); */

        // Hamburger click function
        $hamburger.on("click", function(){
          $(this).toggleClass("is-active");
/*           if(!$("html").hasClass(".slideout-open")){
            var offsetHeight = "0";
            if($(".banner.header").hasClass("banner--sticky"))
            {
              offsetHeight = $(".banner__inner").outerHeight();
            }
            else
            {
              offsetHeight = $(".banner.header").outerHeight();
            }
            $(".slideout-menu").attr("style", "top: "+offsetHeight+"px;");
          }
          else{
            $(".slideout-menu").attr("style", "top: 0px;");
          } */
          $("html").toggleClass("slideout-open");
        });

        // JavaScript to be fired on all pages, after page specific JS is fired
        // Lazy load all the images with class "lazy"
        new LazyLoad({
          elements_selector: ".lazy"
        });
        //load objectFitImages poly-fill on all images.
        objectFitImages();
        
        // `position:sticky` polyfill
        // Add all your to-be-stickied classes here
        var elements = $('.sticky');
        Stickyfill.add(elements);

        herojs.pausebutton();


        // checks for hero-block, and fires hero-related scripts
        if( $('.newheroblock').length ){
          herojs.controlslider( $('.img-rotator'),  6000, false, true, true, false, $('.text-rotator') );
          herojs.slider( $('.text-rotator'), 6000, false, false, true, false );
          herojs.slider( $('.homerotator'),   6000, false, true );
          herojs.init();
        }

        if( $('.newheroblock').length ){
          herojs.slider( $('.galleryrotator'),   6000, false, true );
          herojs.slider( $('.img-rotator'),   6000, false, true );
          herojs.init();
        }

        // if amenities block is present
        if ( $('.contactform').length ){
          contactJs.init();
        }

        // general wow
        new WOW().init();

        // listener for carousels (all)
        headerjs.init();
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  if ($(window).width() < 768){
    $(".colrichheader__nav").addClass("darkHeader");
  }

  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    var mobilesize = $(window).width();

     //>=, not <=
    if (scroll >= 323 && mobilesize >= 768) {
        $(".colrichheader__nav").addClass("darkHeader");
    }

    //>=, not <=
    if (scroll <= 223 && mobilesize >= 768) {
          $(".colrichheader__nav").removeClass("darkHeader");
      }

  }); //missing );

  $('.specialsbanner').on('click', function(){
    $(this).toggleClass('closed');
  });

  $('.read-more').on('click', function(){
    $(this).css('display', 'none');
    $(this).siblings('.hide-content').css('display', 'block');
    $(this).siblings('.read-less').css('display', 'inline-block');
  });

  $('.read-less').on('click', function(){
    $(this).css('display', 'none');
    $(this).siblings('.hide-content').css('display', 'none');
    $(this).siblings('.read-more').css('display', 'block');
  });

  $('.slick-frame').on('init', function(){
    $(this).css({visibility: visible});
  });
  $('.slick-frame').slick();

})(jQuery); // Fully reference jQuery after this point.
