var contactJs = (function($) {
  function _init() {
    var focusFunction = function(classFunc) {
      $(this).parents('label').find('.contactform__labeltext')[classFunc + 'Class']('contactform__labeltext--focus');
    };
    var $formElements = $('.contactform__element')
      .on('focus', function() {
        focusFunction.call(this, 'add');
      })
      .on('blur', function() {
        if (this.value.trim().length === 0) {
          focusFunction.call(this, 'remove');
        }
      });
    var wpcf7FormClass = "contactform__container",
      wpcf7SubmittedClass = wpcf7FormClass + "--submitted",
      wpcf7SuccessClass = wpcf7FormClass + "--success",
      $wpcf7StateElem = $('.' + wpcf7FormClass);
    $(document.body).on('wpcf7mailsent', '.wpcf7', function() {
      $wpcf7StateElem.addClass(wpcf7SuccessClass);
      $.each($formElements, function(idx, elem) {
        focusFunction.call(this, 'remove');
      });
    });
    $(document.body).on('click', '.contactus__link', function() {
      //$wpcf7StateElem.removeClass(wpcf7SuccessClass);
    });
    $(document.body).on('click', '.wpcf7-submit', function() {
      console.log('form button clicked!');
      $wpcf7StateElem.addClass(wpcf7SubmittedClass);
    });
    $(document.body).on('click', '.contactform__mfpclose, .contactformsuccess__backlink', function(e) {
      e.preventDefault();
      $wpcf7StateElem.removeClass(wpcf7SuccessClass);
      $("form.wpcf7-form")[0].reset();
      $formElements.trigger('blur');
    });
    $(document.body).on('wpcf7:submit wpcf7submit', '.wpcf7', function() {
      $wpcf7StateElem.removeClass(wpcf7SubmittedClass);
      console.log('after form submission returned');
    });
    $(document.body).on('wpcf7:mailfailed wpcf7mailfailed', '.wpcf7', function() {
      console.log('mail failed');
    });
    $(document.body).on('wpcf7:mailsent wpcf7mailsent', '.wpcf7', function() {
      console.log('mail sent');
      $(document.body).addClass('wpf7-submitted');
    });
    $(document.body).on('wpcf7:spam wpcf7spam', '.wpcf7', function() {
      console.log('spam!!!');
    });
    $(document.body).on('wpcf7:invalid wpcf7invalid', '.wpcf7', function() {
      console.log('invalid mail');
    });
  }
  return {
    init: function() {
      _init();
    },
    finalize: function() {
    },
  };
})(jQuery);
